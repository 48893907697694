import {Fragment, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

//import assets
import LogoWhite from "../../assets/logo_white.png"
import LogoDark from "../../assets/logo_dark.png"

import Presentation from "../../assets/orthotrainement_presentation.pdf";
import CGU from "../../assets/orthotrainement_CGU.pdf";

//import components
import LoginForm from "./LoginForm";
import CreateForm from "./CreateForm";
import {CustomTooltip} from "../commun/customTooltip";

//import actions
import razActions from "../../actions/razActions";

//import constants
import {getStyleParameters} from "../../constants/localStorage";
import {isExpired, isMobile} from "../../constants/constants";
import {userSelector} from "../../reducers/selectors";

function Login() {
    const dispatch = useDispatch()

    const [form, setForm] = useState(true)
    const [loading, setLoading] = useState(true)

    const {shadow} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")
    const {credentials} = useSelector(userSelector)

    useEffect(() => {
        razActions(dispatch)
        isMobile()
        setLoading(false)
        isExpired(credentials)
    }, [])

    return (
        !loading ? <main className="main-login">
            <div className="login">
                <div className="login-title">
                    <div className="login-logo"><img alt="logo Orthotraînement" src={shadow ? LogoDark : LogoWhite}/></div>
                    <div className="login-name">Orthotraînement</div>
                </div>

                <div className="login-form">
                    <div className="login-banner">
                        <div className="login-banner-text">
                            <FontAwesomeIcon icon="fa-arrow-down"/>
                            Restez informé ! Grosse mise à jour bientôt : nouveaux jeux, mires plus grosses, adaptable à tous les écrans, installable, ...
                        </div>

                        <div className="login-banner-icons">
                            <div className="btn-insta">
                                <CustomTooltip title="Voir le compte Instagram">
                                    <a href="https://www.instagram.com/ortho.trainement/" target="_blank">
                                        <FontAwesomeIcon className="icon" icon="fa-brands fa-instagram"/>
                                    </a>
                                </CustomTooltip>
                            </div>

                            <div className="btn-download">
                                <CustomTooltip title="Télécharger la présentation">
                                    <a rel="noreferrer" href={Presentation} target="_blank" download>
                                        <FontAwesomeIcon className="icon" icon="file-arrow-down"/>
                                    </a>
                                </CustomTooltip>

                                <CustomTooltip title="Télécharger les CGU">
                                    <a rel="noreferrer" href={CGU} target="_blank" download>
                                        <FontAwesomeIcon className="icon" icon="circle-info"/>
                                    </a>
                                </CustomTooltip>
                            </div>
                        </div>
                    </div>


                    {form ?
                        <Fragment>
                            <LoginForm setForm={setForm}/>
                        </Fragment>
                        :
                        <Fragment>
                            <h1>S'inscrire</h1>
                            <CreateForm setForm={setForm}/>
                            <div className="login-signup" onClick={() => setForm(true)}>Déjà inscrit ? <span>Se connecter !</span></div>
                        </Fragment>
                    }
                </div>
            </div>
        </main>
        :
        null
    )
}

export default Login
