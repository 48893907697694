import {Form} from "react-final-form";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Fragment} from "react";
import {useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";

//import components
import {fields} from "../commun/Form/fields";
import {CustomTooltip} from "../commun/customTooltip";

//import services
import {loginServices} from "../../services/loginServices";
import {userServices} from "../../services/userServices";

//import constants
import {encrypt} from "../../constants/crypto";

const ConnectionForm = ({forgot, setForgot}) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const submit = (values) => {
        const valuesToSubmit = {
            email: encrypt(values.email.toLowerCase()),
            password: encrypt(values.password)
        }

        loginServices.login(valuesToSubmit, navigate, dispatch).then(response => (
            response.status === 200 && userServices.find(response.data._id, dispatch, navigate)
        ))
    }

    return (
        <Fragment>
            <h1>Se connecter</h1>
            <Form
                onSubmit={submit}
                render={({handleSubmit, hasValidationErrors}) => (
                    <form onSubmit={handleSubmit}>
                        {fields.email()}
                        {fields.password(forgot, setForgot)}
                        <CustomTooltip title="Se connecter" placement="left">
                            <button disabled={hasValidationErrors} className="btn-nav btn-second" type="submit">
                                <FontAwesomeIcon className="icon" icon="circle-right"/>
                            </button>
                        </CustomTooltip>
                    </form>
                )}
            />
        </Fragment>
    )
}

export default ConnectionForm
