import React from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";

//import components
import {fieldsShape} from "../Form/RadioData";
import {CustomTooltip} from "../customTooltip";
import InputNumber from "./InputNumber";

//import constants
import {getStyle} from "../../../constants/localStorage";

const NavigationLeftFields = ({parameters, onChange}) => {
    const {contrast, size, spacing, shape} = parameters

    const style = getStyle()

    return (
        <>
            <div className="nlf-example">
                <span style={style}>exemple</span>
            </div>

            <div className="nlf-content ftb-container">
                <ToggleButtonGroup value={shape} exclusive onChange={(_, value) => onChange("shape", value)}>
                    {fieldsShape.map((field, index) =>
                        <ToggleButton key={index} value={field.value} className="shape">
                            <CustomTooltip title={field.label}>{field.display}</CustomTooltip>
                        </ToggleButton>
                    )}
                </ToggleButtonGroup>
            </div>

            <InputNumber
                id="contrast"
                label="Contraste (%)"
                min={0}
                max={100}
                value={contrast}
                onChange={onChange}
            />

            <InputNumber
                id="size"
                label="Taille"
                min={10}
                max={60}
                value={size}
                onChange={onChange}
            />

            <InputNumber
                id="spacing"
                label="Espacement"
                min={1}
                max={15}
                value={spacing}
                onChange={onChange}
            />
        </>
    )
}

export default NavigationLeftFields
