import {generateRandomNumber} from "./getRandomNumber";
import moment from "moment";
import {notifications} from "../components/commun/Notifications";
import {getStyleParameters} from "./localStorage";

export const navigationTitles = {
    "/empan": "empan",
    "/antiSaccade": "anti-saccade",
    "/saccade": "saccade",
    "/comparaison": "comparaison",
    "/lecture": "lecture",
    "/balayage": "balayage",
    "/poursuite": "poursuite",
    "/memoire": "mémoire",
}

export const getColor = () => {
    const colors = [
        'var(--dark)', // Noir
        '#FFDB58', // Jaune
        '#8A2BE2', // Violet
        '#FF00FF', // Rose
        '#FF6700', // Orange
        '#40E0D0', // Turquoise
        '#3D0C02', // Marron
        '#A9A9A9',  // Gris foncé
        '#8B8000', // vert jaune
        '#32CD32', // vert
    ]

    return colors[generateRandomNumber(0, colors.length)]
}

export const isMobile = () => (
    window.innerWidth < 1024 && window.location.pathname !== "/mobile"
)

export const isExpired = (credentials) => {
    if (credentials.date && !(credentials.date === moment().format("MM-DD-YYYY"))) {
        notifications.error('Session expirée')
    }
}

export const isShadow = () => {
    const {shadow} = getStyleParameters("oRtHoTrAiNeMeNtStOrAgE")

    if (shadow) {
        document.documentElement.classList.remove('light-theme');
        document.documentElement.classList.add('dark-theme');
    } else {
        document.documentElement.classList.remove('dark-theme');
        document.documentElement.classList.add('light-theme');
    }
}

export const comparisonAllIndex2 = {
    easy: [8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31],
    medium: [9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30],
    difficult: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29]
}

export const comparisonAllIndex3 = {
    easy: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37],
    medium: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38],
    difficult: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39]
}
