export const tradCommon = {
    "contrast": "Contraste (%)",
    "size": "Taille police",
    "spacing": "Interlettre",
    "shape": "Forme"
}

export const tradShape = {
    "shape-1": "majuscule",
    "shape-2": "minuscule",
}

const tradEmpan = () => ({
    type: {title: "Type de mire", display: {"0": "chiffre", "1": "lettre", "2": "logatome"}},
    direction: {title: "Position", display: {"left": "gauche", "center": "centré", "right": "droite", "random": "aléatoire"}},
    number: {title: "Nombre de mire à comparer"},
    time: {title: "Durée"},
})

const tradAntiJerk = () => ({
    type: {title: "Type de mire", display: {"0": "chiffre", "1": "lettre", "3": "dessin"}},
    difficulty: {title: "Difficulté", display: {"easy": "facile", "medium": "moyen", "difficult": "difficile"}},
    orientation: {title: "Position", display: {"0": "horizontale", "1": "verticale", "2": "diagonale (vers le haut)", "3": "diagonale (vers le bas)", "4": "bord gauche", "5": "bord droit", "6": "aléatoire"}},
    time: {title: "Durée"}
})

const tradJerk = () => ({
    type: {title: "Type de mire", display: {"0": "chiffre", "1": "lettre", "3": "dessin"}},
    position: {title: "Position", display: {"static": "mire statique", "movement": "mire en mouvement"}},
    number: {title: "Nombre de mire à comparer"},
    orientation: {title: "Position", display: {"0": "horizontale", "1": "verticale", "2": "diagonale (vers le haut)", "3": "diagonale (vers le bas)", "4": "bord gauche", "5": "bord droit", "6": "aléatoire"}},
    difficulty: {title: "Difficulté", display: {"easy": "facile", "medium": "moyen", "difficult": "difficile"}},
    stopwatch: {title: "Temps", display: {"true": "avec", "false": "sans"}},
    time: {title: "Durée"},
})

const tradComparison = (launch) => {
    let trad = {
        type: {title: "Type de mire", display: {"0": "chiffre", "1": "lettre", "2": "alétoire", "3": "dessin"}},
        difficulty: {title: "Difficulté", display: {"easy": "facile", "medium": "moyen", "difficult": "difficile"}},
        orientation: {title: "Position", display: {"0": "horizontale", "1": "verticale", "2": "diagonale (vers le haut)", "3": "diagonale (vers le bas)"}},
        grid: {title: "Grille", display: {"withGrid": "avec", "noGrid": "sans"}}
    }

    if (launch.orientation === "0") {
        trad = {
            ...trad,
            numberChar: {title: "Nombre de mire à comparer"}
        }

        if (launch.numberChar !== "1") {
            trad = {
                ...trad,
                group: {title: "Type de comparaison", display: {"group": "par groupe", "noGroup": "seul"}},
            }
        }
    }

    return trad
}

const tradReading = (launch) => {
    let trad = {
        type: {title: "Position", display: {"static": "mire statique", "movement": "mire en mouvement", "appearance": "mire en apparition"}},
        textType: {title: "Police", display: {"0": "scripte", "1": "cursive"}},
        wordType: {title: "Type de mire", display: {"0": "syllabe", "1": "logatome", "2": "mot", "3": "texte", "4": "chiffre"}},
    }

    if (launch.type === "appearance") {
        trad = {
            ...trad,
            grid: {title: "Grille", display: {"withGrid": "avec", "noGrid": "sans"}}
        }
    }

    if (launch.type === "movement") {
        trad = {
            ...trad,
            direction: {title: "Direction", display: {"RtL": "de droite à gauche", "LtR": "de gauche à droite"}}
        }
    }

    if (launch.wordType !== "4") {
        trad = {...trad, time: {title: "Durée"}}
    }

    return trad
}

const tradScan = (launch) => {
    let trad = {
        type: {title: "Type de mire", display: {"0": "chiffre", "1": "lettre", "2": "dessin", "3": "mot"}},
        setup: {title: "Organisation", display: {"0": "ordonné", "1": "désordonné"}},
        density: {title: "Densité", display: {"0": "mires nombreuses et serrées", "1": "mires peu nombreuses et espacées"}},
    }

    if (launch.type !== "3" && launch.setup === "0") {
        trad = {
            ...trad,
            group: {title: "Type de comparaison", display: {"group": "par groupe", "noGroup": "seul"}},
        }
    }

    return trad
}

const tradMemory = (launch) => {
    const {type, group} = launch

    let trad = {
        group: {title: "Type de comparaison", display: {"group": "par groupe", "noGroup": "seul"}},
        type: {title: "Position", display: {"static": "statique", "movement": "en mouvement"}},
        target: {title: "Mire", display: {"0": "dessin", "1": "chiffre", "2": "lettre", "3": "aléatoire"}},
        time: {title: "Durée"},
    }

    if (group === "group") {
        trad = {
            ...trad,
            typeComparison: {title: "Comparaison en groupe", display: {"0": "couleur", "1": "forme"}},
            numberChar: {title: "Nombre de mire à comparer (groupe)"},
        }
    }

    if (type === "movement") {
        trad = {
            ...trad,
            direction: {title: "Direction", display: {"RtL": "de droite à gauche", "LtR": "de gauche à droite"}},
        }
    }

    return trad
}

export const trad = {
    "empan": tradEmpan,
    "anti-saccade": tradAntiJerk,
    "saccade": tradJerk,
    "lecture": tradReading,
    "comparaison": tradComparison,
    "balayage": tradScan,
    "memoire": tradMemory
}
